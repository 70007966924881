import React from "react";
import TransparentSection from "../../slave/transparentSection/TransparentSection.js";
import HomeBodySection from "../../slave/homeBodySection/HomeBodySection.js";
import "./home.css";
import {Carousel} from "antd";
import 'antd/dist/reset.css'
import Atterraggio from "../../../assets/IMMAGINI/slider_home1.jpg";
import Title from "../../../assets/PNGLOGO/incalmo_scritta_atterraggio.png";
import SliderImgDue from "../../../assets/IMMAGINI/slider_home2.jpg";
import SliderImgQuattro from "../../../assets/IMMAGINI/slider_home4.jpg";
import SliderImgCinque from "../../../assets/IMMAGINI/slider_home5.jpg";
import SliderImgSei from "../../../assets/IMMAGINI/slider_home6.jpg";
import SliderImgSette from "../../../assets/IMMAGINI/slider_home7.jpg";
import SliderImgOtto from "../../../assets/IMMAGINI/slider_home8.jpg";
import SliderImgNove from "../../../assets/IMMAGINI/slider_home9.jpg";
import {Helmet} from "react-helmet";

export default class Home extends React.Component {
    render() {
        return (
            <>
                <Helmet>
                    <title>Incàlmo Ristorante</title>
                    <meta name="description" content="Incàlmo Homepage"/>
                </Helmet>

                <div className="homeBackground">
                    <TransparentSection/>
                    <HomeBodySection/>
                    <Carousel
                        className="homeCarousel"
                        style={{
                            width: "100%",
                            position: "fixed",
                            top: "150px",
                            height: "750px",
                            overflow: "hidden",
                            zIndex: 20,
                            padding: "0 7%",
                        }}
                        autoplay
                        effect="fade"
                        pauseOnHover={false}
                        speed={1000}
                        autoplaySpeed={3000}
                        dots={false}
                    >
                        <div>
                            <img
                                src={Atterraggio}
                                alt=""
                                style={{width: "100%", height: "auto", margin: "0 auto"}}
                            />
                        </div>
                        <div>
                            <img
                                src={SliderImgDue}
                                alt=""
                                style={{width: "100%", height: "auto", margin: "0 auto"}}
                            />
                        </div>
                        <div>
                            <img
                                src={SliderImgQuattro}
                                alt=""
                                style={{width: "100%", height: "auto", margin: "0 auto"}}
                            />
                        </div>
                        <div>
                            <img
                                src={SliderImgCinque}
                                alt=""
                                style={{width: "100%", height: "auto", margin: "0 auto"}}
                            />
                        </div>
                        <div>
                            <img
                                src={SliderImgSei}
                                alt=""
                                style={{width: "100%", height: "auto", margin: "0 auto"}}
                            />
                        </div>
                        <div>
                            <img
                                src={SliderImgSette}
                                alt=""
                                style={{width: "100%", height: "auto", margin: "0 auto"}}
                            />
                        </div>
                        <div>
                            <img
                                src={SliderImgOtto}
                                alt=""
                                style={{width: "100%", height: "auto", margin: "0 auto"}}
                            />
                        </div>
                        <div>
                            <img
                                src={SliderImgNove}
                                alt=""
                                style={{width: "100%", height: "auto", margin: "0 auto"}}
                            />
                        </div>
                    </Carousel>

                    <div className="atterraggio" style={{width: '-webkit-fill-available'}}>
                        <img
                            src={Atterraggio}
                            alt=""
                            style={{
                                width: "100%",
                                height: "auto",
                                margin: "0 auto",
                            }}
                        />
                        <img
                            src={Title}
                            alt=""
                            style={{
                                padding: "0 35%",
                                width: "100%",
                                height: "auto",
                                margin: "0 auto",
                                marginTop: "-120%",
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }
}
