import React from "react";
import SliderImgUno from "../../../assets/IMMAGINI/menu_1.jpg";
import SliderImgDue from "../../../assets/IMMAGINI/menu_2.jpg";
import SliderImgTre from "../../../assets/IMMAGINI/menu_3.jpg";
import SliderImgQuattro from "../../../assets/IMMAGINI/menu_4.jpg";
import SliderImgCinque from "../../../assets/IMMAGINI/menu_5.jpg";
import SliderImgSei from "../../../assets/IMMAGINI/menu_6.jpg"
import SliderImgSette from "../../../assets/IMMAGINI/menu_7.jpg"
import SliderImgOtto from "../../../assets/IMMAGINI/menu_8.jpg"

import {Carousel} from "antd";
import "./menu.css";
import FoodPDF from "../../../assets/PDFMENU/Food.pdf";
import VinePDF from "../../../assets/PDFMENU/Vini.pdf";
import DolciPDF from "../../../assets/PDFMENU/Dolci.pdf";
import {Helmet} from "react-helmet";

export default function Menu() {
    return (
        <div>
            <Helmet>
                <title>Menu</title>
                <meta name="description" content="Incalmo menu"/>
            </Helmet>
            <div className="menuIntro">
                <Carousel
                    className="menuCarousel"
                    style={{
                        marginTop: "150px",
                        width: "100%",
                        overflow: "hidden",
                        padding: "0 7%",
                    }}
                    autoplay
                    effect="fade"
                    pauseOnHover={false}
                    speed={1000}
                    autoplaySpeed={3000}
                    dots={false}
                >
                    <div>
                        <img
                            src={SliderImgUno}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={SliderImgDue}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={SliderImgTre}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={SliderImgQuattro}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={SliderImgCinque}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={SliderImgSei}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={SliderImgSette}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                    <div>
                        <img
                            src={SliderImgOtto}
                            alt=""
                            style={{width: "100%", height: "auto", margin: "0 auto"}}
                        />
                    </div>
                </Carousel>
                <div className="menuIntroTextContainer">
                    <h2 className='py-5 giorgio'>3 MENU DEGUSTAZIONE</h2>
                    <p className="whiteSub">
                        Per capire chi siamo, facendo la cosa che ci riesce meglio: cucinare per voi.
                    </p>
                </div>
            </div>
            <div className="menuGreenSection">
                <div className="menuGreenCard">
                    <div>
                        <h2 className="greenCardTitle giorgio">IL BOCCIOLO</h2>
                        <p>
                            . Esordio <br/>
                            . Insalata...russa? <br/>
                            . Pasta ripiena con robiola e aglio orsino, crema di mais dolce fermentato <br/>
                            . Patata alla mugniaia <br/>
                            . Biancomangiare di mandorle e pisellini <br/>

                        </p>
                    </div>
                    <div style={{width: "100%"}}>
                        <hr style={{width: "60%"}}/>
                        <p>
                            A persona: € 80,00 <br/> Abbinamento vini: € 60,00
                        </p>
                    </div>
                </div>
                <div className="menuGreenCard">
                    <div>
                        <h2 className="greenCardTitle giorgio">I CLASSICI</h2>
                        <p>
                            . Esordio <br/>
                            . Toast di lingua salmistrata e salsa verde <br/>
                            . Risotto maggiorana, aglio nero e riduzione di peperone rosso <br/>
                            . Pizzicati Crab'o'nara <br/>
                            . La "variante brasiliana" | Rombo al beurre blanc e limone <br/>
                            . Cioccolato, tabacco e ciliegie <br/>

                        </p>
                    </div>
                    <div style={{width: "100%"}}>
                        <hr style={{width: "60%"}}/>
                        <p>
                            A persona: € 90,00 <br/> Abbinamento vini: € 65,00
                        </p>
                    </div>
                </div>
                <div className="menuGreenCard">
                    <div>
                        <h2 className="greenCardTitle giorgio">IL BACCELLO</h2>
                        <p>
                            Un percorso di degustazione di 7 portate che, celate alla vista,
                            proprio come un baccello fa con il suo prezioso contenuto,
                            si svelano poco a poco per condurvi attraverso minuziose scelte
                            delle materie prime contaminate dal nostro entusiasmo affinché
                            si possano imprimere nella vostra memoria.
                        </p>
                    </div>
                    <div style={{width: "100%"}}>
                        <hr style={{width: "60%"}}/>
                        <p>
                            A persona: € 100,00 <br/> Abbinamento vini: € 70,00
                        </p>
                    </div>
                </div>
            </div>
            <div className="menuGreySection">
                <h2 className='giorgio'>LA VITA È TROPPO BREVE</h2>
                <h2 className='giorgio'>PER BERE VINI MEDIOCRI</h2>
                <p className="whiteSub">
                    JOHANN WOLFGANG VON GOETHE <br/>
                    (1749 - 1832)
                </p>
                <a href={VinePDF} target="_blank" rel="noopener noreferrer">
                    <div className="greenBall">
                        <p className="greenballText">SCOPRI LA CARTA VINI</p>
                    </div>
                </a>
            </div>

            <div className="menuWhiteSection">
                <h2 className='giorgio'>GLI INGREDIENTI CHE PIÙ CI COLPISCONO</h2>
                <h2 className='giorgio'>DAI LUOGHI DOVE MEGLIO CRESCONO</h2>

                <div className="d-flex flex-column">
                    <a href={FoodPDF} target="_blank" rel="noopener noreferrer">
                        <div className="whiteBall">
                            <p className="ballText px-3">SCOPRI IL MENU</p>
                        </div>
                    </a>

                    <a href={DolciPDF} target="_blank" rel="noopener noreferrer">
                        <div className="whiteBall">
                            <p className="ballText px-3">SCOPRI I DESSERT</p>
                        </div>
                    </a>

                </div>
            </div>
        </div>
    );
}
